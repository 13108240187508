<template>
  <footer
    id="footer"
    class="relative text-base leading-snug text-white bg-primary-700"
  >
    <div
      class="relative flex flex-col w-auto h-full px-0 pt-12 pb-8 mx-4 my-0 xl:max-w-6xl xl:mx-auto"
    >
      <div class="w-full mb-8">
        <router-link to="/">
          <img src="~@/assets/rmrlogo-light.png" class="mx-auto" />
        </router-link>
      </div>
      <div
        class="flex flex-col flex-wrap justify-around align-top md:flex-row md:justify-center"
      >
        <div
          class="relative flex flex-col items-center order-first mb-16 md:order-none md:mb-8 md:w-60 "
        >
          <h1 class="text-xl font-bold my-4 ">Questions?</h1>
          <!--  -->
          <!-- <a
            href="mailto:info@rmrbenefits.com"
            class="slideFromLeft px-8 py-4 rounded bg-neutral-800 cursor-pointer hover:bg-neutral-900 mb-4"
          >
            <div class="text-wrapper">
              <span class="hidden-text left">
                info@rmrbenefits.com
              </span>
              <span class="visible-text">
                <span class="icon leading flaticon-phone" />
                Send us a message
              </span>
            </div>
          </a>
          <a
            href="tel:8887221223"
            class="slideFromRight px-8 py-4 rounded bg-neutral-800 cursor-pointer hover:bg-neutral-900 mb-4"
          >
            <div class="text-wrapper">
              <span class="visible-text">
                <span class="icon leading flaticon-phone" />
                Give Us a Call
              </span>
              <span class="hidden-text right">
                888.722.1223
              </span>
            </div>
          </a>
          <a
            href="tel:8887221223"
            class="px-8 py-4 rounded bg-neutral-800 cursor-pointer hover:bg-neutral-900"
          >
            <div class="text-wrapper">
              <span class="visible-text">
                Give Us a Call
                <span class="icon trailing flaticon-question" />
              </span>
            </div>
          </a> -->
          <!--  -->
          <Button
            reg
            light
            large-icons
            slide-text-right="888.722.1223"
            trailing="flaticon-phone"
            href="tel:8887221223"
            class="mb-4 w-72 md:w-52"
          >
            Give Us A Call
          </Button>

          <Button
            reg
            light
            large-icons
            trailing="flaticon-email"
            slide-text-right="info@rmrbenefits.com"
            href="mailto:info@rmrbenefits.com"
            tooltip-click="Email Copied!"
            @click-button="setClipboard('info@rmrbenefits.com')"
            class="mb-4 w-72 md:w-52 bg-neutral-900"
          >
            Send Us An Email
          </Button>
          <Button
            reg
            light
            large-icons
            trailing="flaticon-question"
            onclick="zE('messenger', 'open')"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button>
          <div class="w-full mx-0 mt-2 text-center">
            <a href="https://www.facebook.com/RMRBenefits/">
              <div id="facebook" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a href="https://twitter.com/rmrbenefits">
              <div id="twitter" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a
              href="https://www.linkedin.com/company/rocky-mountain-reserve?trk=tyah&trkInfo=clickedVertical%3Acompany%2CentityType%3AentityHistoryName%2CclickedEntityId%3Acompany_514535%2Cidx%3A0"
            >
              <div id="linkedin" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
          </div>
        </div>
      </div>
      <p class="w-full text-center copyright">
        Copyright © 2021 Rocky Mountain Reserve
      </p>
    </div>
  </footer>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  },
  mounted() {
    this.$zendesk.load("a95486a9-0bcf-4bda-99ac-f81566ab1958");
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }
};
</script>

<style scoped>
#linkedin {
  background: url("~@/assets/socialMedia/LinkedIn-White.png");
  background-size: contain;
}

#linkedin:hover {
  background: url("~@/assets/socialMedia/LinkedIn-Color.png");
  background-size: contain;
}

#twitter {
  background: url("~@/assets/socialMedia/Twitter-White.png");
  background-size: contain;
}

#twitter:hover {
  background: url("~@/assets/socialMedia/Twitter-Color.png");
  background-size: contain;
}

#facebook {
  background: url("~@/assets/socialMedia/Facebook-White.png");
  background-size: contain;
}

#facebook:hover {
  background: url("~@/assets/socialMedia/Facebook-Color.png");
  background-size: contain;
}
</style>
