<template>
  <div class="bg-white">
    <div
      class="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-24 lg:py-40"
    >
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
        Frequently asked questions
      </h2>
      <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
        <div
          v-for="faq in faqs"
          :key="faq.id"
          class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
        >
          <dt
            class="text-xl font-bold text-center px-4 py-8 lg:py-16 leading-7 text-gray-100 lg:col-span-5 bg-primary-700 flex items-center justify-center"
          >
            <div>
              {{ faq.question }}
            </div>
          </dt>
          <dd class="mt-4 lg:col-span-7 lg:mt-0 flex items-center">
            <div class="text-base leading-7 text-neutral-800">
              <p v-html="faq.answer"></p>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          id: 1,
          question: "Why is RMR making this change?",
          answer:
            "<p>This new, modern, API-first consumer benefits administration platform delivers many upgrades, including instant claims processing and real-time claims reimbursements, among other advancements. This platform will allow us to administer benefits in a faster, more reliable way for both our employers and your employees.</p>"
        },
        {
          id: 2,
          question: "Who is Elevate?",
          answer:
            "<p>Elevate is the first completely cloud-based, API-driven and AI-enabled consumer-directed benefits platform with a focus on real-time automation. Elevate’s team of industry experts recognized the need for modern technology in an outdated benefits administration industry, which led to the creation of Elevate in 2020. Today, Elevate provides a fully integrated benefits account experience for hundreds of thousands of employees across the U.S. Learn more at <a style='color:blue;' target='_blank' rel='noreferrer' href='https://www.elevate.inc/' >www.elevate.inc</a>.</p>"
        },
        {
          id: 3,
          question: "What will employers have to do during the transition?",
          answer:
            "<p>Employers will have a few minor transition steps, and RMR is here to help you through every step of the way. You will also have a new and improved employer dashboard to use post-transition.</p>"
        },
        {
          id: 4,
          question: "How will employees be impacted",
          answer:
            "<p>We will open new accounts for employees, transfer existing account funds, and send new payment cards. There will be a short quiet period when funds will be unavailable while they are transferring. After the transition date, employees will use the new portal and mobile app.</p><br/><p>Employees with a Wealthcare Saver Bank HSA will need to provide online consent for the funds transfer and liquidate any investments. Employees with a UMB HSA will also need to liquidate any investments.</p>"
        },
        {
          id: 5,
          question: "What bank will be the HSA banking custodian?",
          answer:
            "<p>UMB Bank, n.a. will be the banking custodian for HSAs.</p>"
        },
        {
          id: 6,
          question: "How will my employees be notified?",
          answer:
            "<p>We will send a series of employee notices as well as a transition microsite for all the information that employees need. We will also provide employers communications and information that you can also use to communicate changes with employees.</p>"
        },
        {
          id: 7,
          question: "Will training and educational materials be provided?",
          answer:
            "<p>Yes, we will host training sessions and provide reference materials to familiarize your team with the new features and benefits.</p>"
        }
      ]
    };
  }
};
</script>
